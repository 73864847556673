<template>
  <search-filter
      :items="movements"
      :isActionColumnEnabled="false"
      itemView="list"
      :sortOptions="sortOptions"
      :columns="columns"
      :show-past-years-filter="true"
      :allowAdd="false"
  >
    <!--  =========================== -->
    <!--  ===    Results infos    === -->
    <!--  =========================== -->
    <template #resultInfos="{items}">
      ({{ items.length }})
      <span>{{ $t('estimated:') + ' ' + currency(getEstimated(items)) }}
      </span>
    </template>

    <!--  =========================== -->
    <!--  ===      Grid view      === -->
    <!--  =========================== -->
    <template v-slot:gridView="{item}">
      <card-movement
          :movement="item"
      />
    </template>

    <!--  =========================== -->
    <!--  ===      List view      === -->
    <!--  =========================== -->

    <!--      Cell distance-->
    <template #listView_cell_distance="{item}">
      {{ distance(item.distance) }}
    </template>

    <!--      Cell estimated cost-->
    <template #listView_cell_estimatedCost="{item}">
      {{ currency(item.mileageAllowanceCost) }}
    </template>
  </search-filter>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import mixinMovements from './mixinMovements'

export default {
  components: {},
  mixins: [mixinMovements],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>